@font-face {
  font-family: "AIABold";
  src: local("AIABold"), url("../assets/fonts/AIAEverest-Bold.eot");
  src: local("AIABold"), url("../assets/fonts/AIAEverest-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AIAEverest-Bold.woff") format("woff"), url("../assets/fonts/AIAEverest-Bold.woff2") format("woff2"), url("../assets/fonts/AIAEverest-Bold.ttf") format("truetype"), url("../assets/fonts/AIAEverest-Bold.svg#AIAEverestBold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AIACondensed";
  src: local("AIACondensed"), url("../assets/fonts/AIAEverest-Condensed.eot");
  src: local("AIACondensed"), url("../assets/fonts/AIAEverest-Condensed.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AIAEverest-Condensed.woff") format("woff"), url("../assets/fonts/AIAEverest-Condensed.woff2") format("woff2"), url("../assets/fonts/AIAEverest-Condensed.ttf") format("truetype"), url("../assets/fonts/AIAEverest-Condensed.svg#AIAEverestCondensed") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AIACondensedMedium";
  src: local("AIACondensedMedium"), url("../assets/fonts/AIAEverest-CondensedMedium.eot");
  src: local("AIACondensedMedium"), url("../assets/fonts/AIAEverest-CondensedMedium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AIAEverest-CondensedMedium.woff") format("woff"), url("../assets/fonts/AIAEverest-CondensedMedium.woff2") format("woff2"), url("../assets/fonts/AIAEverest-CondensedMedium.ttf") format("truetype"), url("../assets/fonts/AIAEverest-CondensedMedium.svg#AIAEverestCondensedMedium") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "AIAExtraBold";
  src: local("AIAExtraBold"), url("../assets/fonts/AIAEverest-ExtraBold.eot");
  src: local("AIAExtraBold"), url("../assets/fonts/AIAEverest-ExtraBold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AIAEverest-ExtraBold.woff") format("woff"), url("../assets/fonts/AIAEverest-ExtraBold.woff2") format("woff2"), url("../assets/fonts/AIAEverest-ExtraBold.ttf") format("truetype"), url("../assets/fonts/AIAEverest-ExtraBold.svg#AIAEverestExtraBold") format("svg");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AIAMedium";
  src: local("AIAMedium"), url("../assets/fonts/AIAEverest-Medium.eot");
  src: local("AIAMedium"), url("../assets/fonts/AIAEverest-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AIAEverest-Medium.woff") format("woff"), url("../assets/fonts/AIAEverest-Medium.woff2") format("woff2"), url("../assets/fonts/AIAEverest-Medium.ttf") format("truetype"), url("../assets/fonts/AIAEverest-Medium.svg#AIAEverestMedium") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "AIARegular";
  src: local("AIARegular"), url("../assets/fonts/AIAEverest-Regular.eot");
  src: local("AIARegular"), url("../assets/fonts/AIAEverest-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AIAEverest-Regular.woff") format("woff"), url("../assets/fonts/AIAEverest-Regular.woff2") format("woff2"), url("../assets/fonts/AIAEverest-Regular.ttf") format("truetype"), url("../assets/fonts/AIAEverest-Regular.svg#AIAEverestRegular") format("svg");
  font-weight: 400;
  font-style: normal;
}
